.contact-form {
  height: 80vh;
  padding: 0rem 3rem 0 3rem;
  display: flex;
  margin-top: 4rem;
}

.c-right {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.c-right > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.c-right .user {
  width: 20rem;
  height: 2rem;
  padding: 0.3rem;
  outline: none;
  border-radius: 8px;
  border: 2px solid var(--orange);
  font-size: 16px;
}

textarea {
  height: 4rem !important;
}

.c-blur {
  top: 1rem;
  left: 8rem;
}
@media screen and (max-width: 480px) {
  .contact-form {
    padding: 0;
    flex-direction: column;
    gap: 5rem;
    height: 40rem;
  }
  .c-right .user {
    width: 16rem;
  }
}
