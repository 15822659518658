.floatingdiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  box-shadow: var(--boxShadow);
  border-radius: 17px;
  padding: 0px 28px 0px 0px;
  height: 4.5rem;
}
.floatingdiv > img {
  transform: scale(0.4);
}

.floatingdiv > span {
  font-family: sans-serif;
  font-size: 16px;
}
