.intro {
  height: 77vh;
  display: flex;
  margin-top: 6rem;
}
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
}

.i-name > :nth-child(1) {
  font-size: 3rem;
  font-weight: bold;
  color: var(--black);
}
.i-name > :nth-child(2) {
  font-size: 3rem;
  font-weight: bold;
  color: var(--orange);
}
.i-name > :nth-child(3) {
  font-size: 14px;
  font-weight: 100;
  color: var(--gray);
  margin-top: 10px;
}

.i-button {
  width: 6rem;
  height: 2rem;
}

.i-icons {
  display: flex;
  margin-top: 3rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}

.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.i-right > :nth-child(2) {
  transform: scale(0.67);
  left: -3rem;
  top: -4.6rem;
}
.i-right > :nth-child(3) {
  transform: scale(0.45);
  left: -23%;
  top: -90%;
  border-radius: 27%;
}
.i-right > :nth-child(4) {
  transform: scale(0.6);
  top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;
}

.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background-color: #edd0ff;
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .intro {
    flex-direction: column;
    gap: 1rem;
    height: 64rem;
    margin-top: 1rem;
  }

  .i-right {
    transform: scale(0.8);
    left: -6.5rem;
  }

  .boy {
    left: -7rem !important;
  }
  .i-right .blur {
    display: none;
  }
  .floating-div:nth-of-type(1) {
    top: -7rem !important;
    left: 2rem !important;
  }
  .floating-div:nth-of-type(2) {
    left: 3rem !important;
  }
  .f-img {
    display: none;
  }
}
