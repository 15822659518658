.services {
  height: 30rem;
  padding: 0 3rem 0 3rem;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 13rem;
  padding-top: 3rem;
  gap: 6rem;
}
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
}
.awesome > :nth-child(2) {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--orange);
}
.awesome > :nth-child(3) {
  font-size: 14px;
  color: var(--gray);
  margin-top: 1rem;
  width: 20rem;
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}
.cards {
  position: relative;
}
.cards > * {
  position: absolute;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}
.s-blur1 {
  left: -18rem;
  top: 13rem;
}
@media screen and (max-width: 480px) {
  .services {
    margin-top: -1.5rem;
    flex-direction: column;
    gap: 2rem;
    height: 66rem;
    padding: 0;
  }
  .awesome {
    text-align: center;
  }
  .awesome > :nth-child(3) {
    width: 100%;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }
  .s-blur2 {
    display: none;
  }
  .s-blur1 {
    display: none;
  }
}
